<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import { storeConfig } from "../../stores/storeConfig";
	import { IChatHandler, IConfigObject } from "../../types/sdk";
	import { chatHandlers } from "../../utils/chatHandlers";

	const dispatch = createEventDispatcher();
	let config: IConfigObject;
	let chatHandler: IChatHandler | null;

	$: config = $storeConfig;
	$: chatType = config?.chatOptions?.type;
	$: chatHandler = chatType ? chatHandlers[chatType] : null;
	$: chatEnabled = config?.chatOptions?.enabled;
	$: chatNavButtonTriggerMainChat =
		config?.chatOptions?.enableChatNavButtonTrigger ?? false;
	export let bgColor: string;
	export let isCTABarLift = false;

	function clickHandler(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		if (chatNavButtonTriggerMainChat) {
			chatHandler && chatHandler.openChat(() => dispatch("openChat"));
		} else {
			dispatch("chatNavigate");
		}
	}
</script>

{#if chatHandler && chatEnabled}
	<div
		on:click={clickHandler}
		style="--gap:16px;--height:55px;--width:25px;background-color:{bgColor};"
		class="u-shadow-[0_6px_12px_rgba(0,0,0,0.1)] u-select-none u-rounded-default u-absolute {config.widgetToLeft
			? 'u-left-0'
			: 'u-right-0'} {isCTABarLift
			? 'u-bottom-[calc(100%+var(--gap))]'
			: 'sm:u-bottom-[calc(100%+var(--gap))] u-bottom-[calc(1%+var(--gap))]'} u-h-[var(--height)] u-w-[var(--width)] u-m-0 u-flex u-justify-center u-items-center u-flex-col-reverse">
		<p
			class="u-px-1 u-h-[var(--width)] u-w-[var(--height)] u-flex u-items-center u-justify-center u-font-inter u-text-center u-font-semibold u-uppercase u-text-white u-text-xs u-tracking-[0.03em] u-rotate-[271deg]">
			chat
		</p>
	</div>
{/if}
